import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "code", "name", "address1", "address2", "address3", "address4", "county", "postcode",
    "firstname", "lastname", "telephone", "email", "feedback"
   ]

  initialize() {
    if (this.hasFeedbackTarget) {
      this.originalFeedback = this.feedbackTarget.innerHTML
    }
  }
   
  lookupCustomer(event) {
    const query = this.codeTarget.value
    if (query !== '') {
      if (this.hasFeedbackTarget) {
        this.feedbackTarget.innerHTML = '<i class="fas fa-sync fa-spin"></i>'
      }
      $.rails.ajax({
        type: 'get',
        url: `/customer/lookup/${query}`,
        success: data => {
          if (data.customer_id !== '0') {
            this.codeTarget.value = data.code
            this.nameTarget.value = data.name
            this.address1Target.value = data.address1
            this.address2Target.value = data.address2
            this.address3Target.value = data.address3
            this.address4Target.value = data.address4
            this.postcodeTarget.value = data.postcode

            this.firstnameTarget.value = data.contact_first_name
            this.lastnameTarget.value = data.contact_last_name
            this.telephoneTarget.value = data.telephone
            this.emailTarget.value = data.email
          }
        },
        complete: event => {
          if (this.hasFeedbackTarget) {
            this.feedbackTarget.innerHTML = this.originalFeedback
          }
        
      }})
    }
  }
}