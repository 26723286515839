import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['id', 'search', 'list']

  connect() {
    this.listTarget.hidden = true
  }

  select(event) {
    this.searchTarget.value = event.currentTarget.options[event.currentTarget.selectedIndex].text
  }

  filter(event) {
    if (this.searchTarget.value != '') {
      const filter = new RegExp(this.searchTarget.value, 'i')
      for (let index = 0; index < this.listTarget.options.length; index++) {
        const option = this.listTarget.options[index]
        option.hidden = option.text.search(filter) == -1
      }
      this.listTarget.hidden = false
    } else {
      this.listTarget.hidden = true
    }
  }
}