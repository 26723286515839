import { Controller } from 'stimulus'

export default class extends Controller {
  static targets= [ "result", "multiplier" ]

  connect() {
    this.multiplicand = parseFloat(this.data.get('multiplicand'))
    this.renderResult(parseFloat(this.multiplierTarget.value))
  }

  update(event) {
    const multiplier = parseFloat(event.currentTarget.value)

    this.renderResult(multiplier)
  }
  
  renderResult(multiplier) {
    this.resultTarget.value = (this.multiplicand * multiplier).toFixed(2)
  }
}
