import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "feedback", "toggle" ]

  feedback(event) {
    let delay = 500
    if (this.data.has('delay')) delay = Number(this.data.get('delay'))
    setTimeout(() => {
      this.toggleTarget.hidden = !this.toggleTarget.hidden 
      this.feedbackTarget.innerHTML = "<div class='text-center'><i class='fas fa-spinner fa-spin fa-3x'></i></div>"
    }, delay)
  }
}