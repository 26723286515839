import { Controller } from "stimulus"
import consumer from "../channels/consumer"

export default class extends Controller {
  static targets = [ "html" ]

  initialize() {
    let feedbackContoller = this
    this.feedbackChannel = consumer.subscriptions.create({ channel: "FeedbackChannel", request_id: this.data.get('request') }, {
      connected() {
        feedbackContoller.listen()
      },
      received(data) {
        if (data.feedback) console.log(data.feedback)
        if (data.html) {
          feedbackContoller.htmlTarget.innerHTML = data.html
        }
      }
    })
  }

  connect() {
    this.listen()
  }

  disconnect() {
    if (this.feedbackChannel) {
      this.feedbackChannel.perform('listen')
    }
  }

  listen() {
    if (this.feedbackChannel) {
      this.feedbackChannel.perform('stop', {})
    }
  }
}