import { Controller } from "stimulus"
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import { throws } from "assert";

export default class extends Controller {
  static targets = []

  connect() {
    let offset = 0;
    this.calendar = new Calendar(this.element, {
      plugins: [ bootstrapPlugin, dayGridPlugin, interactionPlugin ],
      themeSystem: 'bootstrap',
      header: {
        left: 'prev,today,next',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek'
      },
      timeZone: 'GMT',
      eventSources: [
        {id: 'notes', url: '/events/notes.json'},
        {id: 'events', url: '/events/events.json'},
        {id: 'inspections', url: '/events/inspections.json'}
      ],
      views: {
        monthWithFutureWeeks: {
          type: 'dayGrid',
          visibleRange: function(currentDate) {
            let d = new Date(currentDate)
            return {
              start: d.setDate(d.getDate() - 1),
              end: d.setDate(d.getDate() + 60)
            }
          }
        }
      },
      height: function() {
        const height = window.innerHeight - 80 - offset
        if (offset == 0) offset = 60
        return height
      },
      defaultView: 'dayGridMonth',
      defaultDate: this.data.get('date'),
      eventStartEditable: true,
      eventDurationEditable: false,
      eventDrop: this.eventMove,
      eventResize: this.eventDuration,
      eventBackgroundColor: '#96c1e0',
      eventBorderColor: '#055a7c',
      eventTextColor: '#055a7c',
      eventMouseEnter: this.showPopover,
      eventMouseLeave: this.hidePopover,
      eventDragStart: this.hidePopover,
      eventRender: this.renderEvent,
      dateClick: this.dayNote,
      eventClick: this.eventClick
    })
    $('[data-toggle=popover]').popover({
      container: 'body',
      trigger: 'manual',
      placement: 'top',
      selector: '.has-popover'
    })
    this.calendar.render()
  }

  updateNotes(event) {
    this.calendar.getEventSourceById('notes').refetch()
  }

  dayNote(info) {
    $.rails.ajax({
      type: 'GET',
      url: `/events/${info.dateStr}/notes`
    })
  }

  eventClick(info) {
    $.rails.ajax({
      type: 'GET',
      url: `/events/${info.event.start.toISOString().slice(0,10)}/notes`
    })
  }

  renderEvent(info) {
    info.el.classList.add(`event-${info.event.extendedProps.type}`)
    if (info.event.extendedProps.hasOwnProperty('icon')) {
      info.el.querySelector('.fc-content').insertAdjacentHTML('afterbegin', `<i class="fa fa-${info.event.extendedProps.icon}"></i>`)      
    }
  }

  eventMove(info) {
    if (info.event.extendedProps.type == 'inspection') {
      const lastInspected = new Date(info.oldEvent.extendedProps.lastInspectionDate)
      const nextInspection = new Date(info.oldEvent.extendedProps.nextInspectionDate)
      const proposedDate = info.event.start
      if (proposedDate <= lastInspected || proposedDate > nextInspection) {
        info.revert()
        return
      }
    }
    $.rails.ajax({
      type: 'PATCH',
      url: `/events/${info.event.id}`,
      data: `event[new_date]=${info.event.start.toJSON()}`
    })
  }

  eventDuration(info) {
    $.rails.ajax({
      type: 'PATCH',
      url: `/events/${info.event.id}`,
      data: `event[delivery_duration_change]=${info.endDelta.days}`
    })
  }

  showPopover(info) {
    let title = `<b>Job #${info.event.id}</b> for ${info.event.extendedProps.customer}`
    if (info.event.extendedProps.type == 'note') {
      title = `<b>Notes for ${info.event.start.toDateString()}</b>`
    }
    $(info.el).popover({
      container: 'body',
      trigger: 'manual',
      placement: 'left',
      html: true,
      title: title,
      content: info.event.extendedProps.description
    })
    $(info.el).popover('show')
  }

  hidePopover(info) {
    $(info.el).popover('hide')
  }
}
