// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
import Rails from "@rails/ujs"
import "chartkick/chart.js"

Rails.confirm = function(message, element) {
  const html = `
  <div class="modal" tabindex="-1" role="dialog" id="confirmation">
  <div class="modal-dialog" role="document">
  <div class="modal-content">
  <div class="modal-header">
  <h5 class="modal-title">Please Confirm</h5>
  </div>
  <div class="modal-body">
  <p>${message}</p>
  </div>
  <div class="modal-footer">
  <button type="button" class="btn btn-danger" data-dismiss="modal">
    <i class="fa fa-fw fa-times-circle"></i>
    Cancel
  </button>
  <button type="button" class="btn btn-success confirm" data-dismiss="modal">
    <i class="fa fa-fw fa-check-circle"></i>
    Confirm
  </button>
  </div>
  </div>
  </div>
  </div>`
  $(html).modal()
  $('#confirmation .confirm').on('click', function(e) {
    $(element).removeAttr('data-confirm')
    element.click()
  })
  
  return false
}

Rails.start()
require("local-time").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import 'datatables.net-bs4/css/dataTables.bootstrap4.css'
require('datatables.net-bs4')
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css'
require('datatables.net-fixedheader-bs4')
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.css'
require('datatables.net-responsive-bs4')

import "controllers"
import bsCustomFileInput from "bs-custom-file-input"
// import "./src/docCookies"

document.addEventListener("turbolinks:load", function() {
  $('table.data-table:not(.dataTable)').DataTable({
    language: {
      search: "Search this table:"
    },
    lengthMenu: [[10, 15, 60, 100, -1], [10, 15, 60, 100, 'All']],
    pageLength: 10,
    stateSave: true
  })
  $('#remoteModal').on("shown.bs.modal", function(event) {
    $('[autofocus]', event.currentTarget).focus()
  })
  bsCustomFileInput.init()
})

document.addEventListener("turbolinks:before-cache", function() {
  navbarSideMenu.classList.remove('show')
})
